'use client';

import React, { useState, useEffect, useRef } from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const BarChartComponent = ({data}) => {

  function sum( obj ) {
    var sum = 0;
    for( var el in obj ) {
      if( obj.hasOwnProperty( el ) ) {
        sum += parseFloat( obj[el] );
      }
    }
    return sum;
  }

  const [percentage, setPercentage] = useState([]);

  useEffect(() => {

    //const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0);
    const total = sum(data);
    const successful = Math.round(((data.delivered + data.read) / total)*100);
    const failed = Math.round(((data.failed + data.undelivered) / total)*100);
    setPercentage([{
      name: 'Percentage',
      successful: successful,
      failed: failed
    }])
    console.log(data)
  }, [data])
  return (
    <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={percentage}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Legend />
          <Bar dataKey="successful" fill="#2b8e4f" label={{ position: "top" }}/>
          <Bar dataKey="failed" fill="#cb0000" label={{ position: "top" }}/>
        </BarChart>
      </ResponsiveContainer>
  );
};

export default BarChartComponent;