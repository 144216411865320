import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Navigation';
import axios from '../api/axios';
import { PlusIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid';
import Error from './Error';
import NewUser from './NewUser';
import EditUser from './EditUser';
import useAuth from '../hooks/useAuth';

export default function Team() {

    const {auth} = useAuth();

    const [user, setUser] = useState({});

    useEffect(() => {
      if(auth){
          setUser({
              id: auth?.id,
              firstname: auth?.firstname,
              lastname: auth?.lastname,
              username: auth?.username,
              role: auth?.role,
              companyId: auth?.companyId
          })
      }
  }, [auth])

    const [users, setUsers] = useState([]);
    const [errorTrue, setErrorTrue] = useState(false);
    const [error, setError] = useState('');
    const [newUser, setNewUser] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [userToEdit, setUserToEdit] = useState({});

    
    useEffect(() => {

      if(user){
          axios.get(`/users/${auth?.companyId}`)
          .then(response => {
              setUsers(response.data);
          })
          .catch(error => {
            console.error('Error fetching users:', error);
            //setErrorTrue(true);
            //console.log(error);
            //setError(error.message);
            /*<Error state={errorTrue} error={error} onClose={() => setErrorTrue(false)}/>*/
          });
      }
    }, [user])

    return (
    <Nav>
        <NewUser state={newUser} onClose={() => setNewUser(false)}/>
        <EditUser state={editUser} user={userToEdit} onClose={() => setEditUser(false)}/>
        <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              onClick={() => setNewUser(true)}
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add user
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Role
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((user) => (
                    <tr key={user._id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {user.firstname + " "}
                        {user.lastname == undefined ?
                        '' : user.lastname}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.registered ? 'Active' : 'Pending'}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.username}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.role}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button className="text-indigo-600 hover:text-indigo-900"
                        value={user}
                        onClick={() => {setUserToEdit({id: user._id, firstname: user.firstname, lastname: user.lastname, username: user.username, role: user.role}); setEditUser(true)}}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </Nav>
    )
}