import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Dashboard from './components/Dashboard';
import Team from './components/Team';
import Profile from './components/Profile';
import Register from './components/Register';
import Conversations from './components/Conversations';
import Inbox from './components/Inbox';
import Chat from './components/Chat';
import NotFound from './components/404';
import Unauthorised from './components/401';
import Public from './components/Public';
import Onboard from './components/Onboard';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Archive from './components/Archive';
import Test from './components/Test';
import Support from './components/Support';
import Companies from './components/Companies';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';

function App() {
  return (
        <div className="pages">
          <Routes>
            <Route 
              path="*"
              element={<NotFound />}
            />
            <Route 
              path="/"
              element={<Public />}
            />
            <Route 
              path="/test"
              element={<Test />}
            />
            <Route 
              path="/login"
              element={<Login />}
            />
            <Route 
              path="/register"
              element={<Register />}
            />
            <Route 
              path="/forgotten_password"
              element={<ForgotPassword />}
            />
            <Route 
              path="/reset"
              element={<ResetPassword />}
            />
            <Route 
              path="/unauthorised"
              element={<Unauthorised />}
            />
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth allowedRoles={['User','Admin','Sudo']} />}>
                <Route 
                  path="/archive"
                  element={<Archive />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['User','Admin','Sudo']} />}>
                <Route 
                  path="/conversations"
                  element={<Conversations />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['User','Admin','Sudo']} />}>
                <Route 
                  path="/conversation/:id"
                  element={<Chat />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['User','Admin','Sudo']} />}>
                <Route 
                  path="/dashboard"
                  element={<Dashboard />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['User','Admin','Sudo']} />}>
                <Route 
                  path="/inbox"
                  element={<Inbox />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['Sudo']} />}>
                <Route 
                  path="/onboard"
                  element={<Onboard />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['Sudo']} />}>
                <Route 
                  path="/companies"
                  element={<Companies />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['User','Admin','Sudo']} />}>
                <Route 
                  path="/profile"
                  element={<Profile />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['User','Admin','Sudo']} />}>
                <Route 
                  path="/support"
                  element={<Support />}
                >
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={['Admin','Sudo']} />}>
                <Route 
                  path="/team"
                  element={<Team />}
                >
                </Route>
              </Route>
              </Route>
          </Routes>
        </div>
  );
}

export default App;