import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Nav from './Navigation';
import axios from '../api/axios';
import { ArrowLeftEndOnRectangleIcon, ArrowRightStartOnRectangleIcon, ChatBubbleLeftRightIcon, CursorArrowRaysIcon } from '@heroicons/react/24/solid';
import useAuth from '../hooks/useAuth';

import BarChart from './BarChart';
import PieChart from './PieChart';
import Delivery from './Delivery';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {

  const [data,setData] = useState({});

  const [data2,setData2] = useState([]);

  const {auth} = useAuth();

  const [user, setUser] = useState({});

  const [usageObject, setUsageObject] = useState({});

  useEffect(() => {
    if(auth){
      setUser(auth);
    }
  }, [auth])


  useEffect(() => {

    if(auth?.companyId){
        axios.get(`/usage/${auth.companyId}`)
        .then(response => {
          setUsageObject({
            inbound: response.data?.inbound,
            outbound: response.data?.outbound,
            conversations: response.data?.conversations,
            clicks: response.data?.clicks
          });
          setData(
            {
              read: response.data?.read,
              delivered: response.data?.delivered,
              failed: response.data?.failed,
              undelivered: response.data?.undelivered
            }
          );
          setData2([
            { name: 'Read', value: response.data?.read },
            { name: 'Delivered', value: response.data?.delivered },
            { name: 'Failed', value: response.data?.failed },
            { name: 'Undelivered', value: response.data?.undelivered },
          ])
        })
        .catch(error => {
          console.error('Error fetching usage data:', error);
          //setErrorTrue(true);
          //console.log(error);
          //setError(error.message);
          /*<Error state={errorTrue} error={error} onClose={() => setErrorTrue(false)}/>*/
        });
    }
  }, [auth])

  return (
    <Nav>
    <div className="mt-5">
      <h3 className="text-base font-semibold leading-6 text-gray-900">{user ? user?.company + "'s usage for the past 60 days" : 'loading...'}</h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <div
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-sky-500 p-3">
                <ArrowLeftEndOnRectangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Inbound</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{usageObject?.inbound}</p>
              
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">

                </div>
              </div>
            </dd>
          </div>
          <div
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-red-500 p-3">
                <ArrowRightStartOnRectangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Outbound</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{usageObject?.outbound}</p>
              
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">

                </div>
              </div>
            </dd>
          </div>
          <div
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-green-500 p-3">
                <ChatBubbleLeftRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Initiated</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{usageObject?.conversations}</p>
              
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">

                </div>
              </div>
            </dd>
          </div>
          <div
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <CursorArrowRaysIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Clicks</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{usageObject?.clicks}</p>
              
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">

                </div>
              </div>
            </dd>
          </div>
      </dl>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-2">
          <div
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dd className="ml-2 flex items-baseline pb-6 sm:pb-7">
              
              <div className="grid grid-cols-1 w-full gap-10 max-w-[1400px]">
                <div className="flex flex-col items-center justify-center p-0 border border-white rounded-xl h-[400px]">
                  <BarChart data={data}/>
                </div>
              </div>
              
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">

                </div>
              </div>
            </dd>
          </div>

          <div
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <Delivery stats={data2}/>
            <dd className="ml-2 flex items-baseline pb-6 sm:pb-7">
              
              <div className="grid grid-cols-1 w-full gap-10 max-w-[1400px]">
                <div className="flex flex-col items-center justify-center p-0 border border-white rounded-xl h-[400px]">
                  <PieChart data={data2}/>
                </div>
              </div>
              
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">

                </div>
              </div>
            </dd>
          </div>
      </dl>
    </div>
    </Nav>
  )
}
