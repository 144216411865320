import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';

export default function ViewUsage(props) {


  const {auth} = useAuth();

  const [user,setUser] = useState({});

  useEffect(() => {
      if(auth){
      setUser(auth);
      }
  }, [auth])

  const { onClose,usage } = props;
    
  const [open, setOpen] = useState(props.state);

  const [usageObject, setUsageObject] = useState({
      name: '',
      inbound: 0,
      outbound: 0,
      conversations: 0
  });

  useEffect(() => {

    if(usage){
        axios.get(`/usage/${usage?.companyId}`)
        .then(response => {
          setUsageObject({
            name: usage?.name,
            inbound: response.data?.inbound,
            outbound: response.data?.outbound,
            conversations: response.data?.conversations
          });
        })
        .catch(error => {
          console.error('Error fetching usage data:', error);
          //setErrorTrue(true);
          //console.log(error);
          //setError(error.message);
          /*<Error state={errorTrue} error={error} onClose={() => setErrorTrue(false)}/>*/
        });
    }
  }, [usage])

  const closePanel = e => {
    onClose();
    setUsageObject({
        name: '',
        inbound: 0,
        outbound: 0,
        conversations: 0
    })
  };

  useEffect(() => {
    setOpen(props.state);
  }, [props.state]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closePanel}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 text-lg">
                          {usageObject?.name}'s usage this month
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={closePanel}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-5 flex-1 px-4 sm:px-6">

                    <h2 className="text-base font-semibold leading-7 text-gray-900">Inbound</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {usageObject?.inbound}
                    </p>
                    <br></br>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Outbound</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {usageObject?.outbound}
                    </p>
                    <br></br>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Conversations</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {usageObject?.conversations}
                    </p>

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}