import { io } from 'socket.io-client';

const URL = 'wss://api.abc-tech.co.uk';

export const socket = io(URL, {
    autoConnect: false,
    transports: ['websocket']
});

socket.on("connect_error", (err) => {
    console.log('socket.io: '+JSON.stringify(err))
});
