import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

const type = {
    "Read": "read",
    "Delivered": "delivered",
    "Failed": "failed",
    "Undelivered": "undelivered"
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Delivery({stats}) {
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-3">
            <dt className={`text-base font-normal text-sm text-black`}>{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className={`flex items-baseline text-xl font-semibold ${type[item.name]}`}>
                {item.value}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}