import React, { Component, useState, useEffect } from 'react';
import { Link,useSearchParams,useNavigate } from 'react-router-dom';
import Error from './Error';
import axios from '../api/axios';
import { XCircleIcon } from '@heroicons/react/20/solid';
import logo from '../images/logo.png';

export default function ResetPassword() {

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get('token');

    const [tokenValid,setTokenValid] = useState(false);

    const [error, setError] = useState('');
    const [checkIsError, setCheckIsError] = useState(false);
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
      if(token){
            axios.get(`/users/reset/${token}`)
        .then(response => {
            console.log(response.data);
            setTokenValid(true);
            setId(response.data);
        })
        .catch(error => {
            setTokenValid(false);
            console.error('Error:', error);
        });
      } else {
        setTokenValid(false);
      }
    }, [token])

    const handleSubmit = e => {
      e.preventDefault();
  
      const userObject = {
        id: id,
        password: password
      };
  
      axios.patch(`/users/reset`, userObject)
        .then(res => {
          navigate('/login');
        })
        .catch(error => {
            setError(error);
            console.log(error);
        });

    };

    return (

        tokenValid ?
        <>
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-30 w-auto"
                src={logo}
                alt="Your Company"
              />
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Let's get you back up and running
              </h2>
            </div>
    
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    
              {
              
              checkIsError ?
              
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">There was an error</h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul role="list" className="list-disc space-y-1 pl-5">
                        <li>{error}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
    
              :
    
              ''
    
              }
    
              <form className="space-y-6" onSubmit={handleSubmit} >
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    New password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={password}
                      required
                      onChange={(e) => {setPassword(e.target.value); setCheckIsError(false);}}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
    
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Reset password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>

        :

        <>
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
            <p className="text-base font-semibold text-indigo-600">400</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Link has expired</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Try resetting your password again.</p>
            </div>
        </main>
        </>
      )
  }  