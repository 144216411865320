import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Navigation';
import axios from '../api/axios';
import { PlusIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid';
import Error from './Error';
import ViewUsage from './ViewUsage';
import useAuth from '../hooks/useAuth';

export default function Companies() {

    const {auth} = useAuth();

    const [user, setUser] = useState({});

    useEffect(() => {
      if(auth){
          setUser({
              id: auth.id,
              firstname: auth.firstname,
              lastname: auth.lastname,
              username: auth.username,
              role: auth.role
          })
      }
  }, [auth])

    const [companies, setCompanies] = useState([]);
    const [errorTrue, setErrorTrue] = useState(false);
    const [error, setError] = useState('');
    const [viewUsage, setViewUsage] = useState(false);
    const [usageToView, setUsageToView] = useState({});

    
    useEffect(() => {

      if(auth?.username){
          axios.get(`/company`)
          .then(response => {
            setCompanies(response.data);
          })
          .catch(error => {
            console.error('Error fetching users:', error);
            //setErrorTrue(true);
            //console.log(error);
            //setError(error.message);
            /*<Error state={errorTrue} error={error} onClose={() => setErrorTrue(false)}/>*/
          });
      }
    }, [auth])

    return (
    <Nav>
        <ViewUsage state={viewUsage} usage={usageToView} onClose={() => setViewUsage(false)}/>
        <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Companies</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all registered companies.
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Twilio Account Sid
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {companies.map((company) => (
                    <tr key={company._id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {company.company}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company._id}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.twilioSid}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button className="text-indigo-600 hover:text-indigo-900"
                        value={company}
                        onClick={() => {setUsageToView({name: company.company, companyId: company._id}); setViewUsage(true)}}
                        >
                          View usage
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </Nav>
    )
}
