import loadingGif from '../images/loading.gif';
import Nav from './Navigation';


const Test = () => {

    return (
        <>
        {
                    <Nav>
                        <div class="flex justify-center items-center mt-36">
                            <img class="block mx-auto"
                            width={150}
                            height={150}
                            src={loadingGif}
                            alt="Loading"
                            />
                        </div>
                    </Nav>
            }
        </>
    )
}

export default Test