import { useState } from 'react'
import { ChevronDownIcon,QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import axios from '../api/axios';
import Notification from './Notificaiton';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Onboard() {

    const [agreed, setAgreed] = useState(false);
    const [notification, setNotification] = useState(false);
    const [notificationObject, setNotificationObject] = useState({
        notification: 'success',
        title: 'Success!',
        message: 'The company was successfully created.'
      });
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [twilioSid, setTwilioSid] = useState('');
    const [twilioAuth, setTwilioAuth] = useState('');
    const [contentSid, setContentSid] = useState('');
    const [messagingServiceSid, setMessagingServiceSid] = useState('');


    const handleSubmit = e => {
        e.preventDefault();

        const companyObject = {
            firstname: firstname,
            lastname: lastname,
            company: company,
            email: email,
            twilioSid: twilioSid,
            twilioAuth: twilioAuth,
            contentSid: contentSid,
            messagingServiceSid: messagingServiceSid
        };

        axios.post(`/company`, companyObject)
        .then(res => {
            console.log(res.data);
            if(res.status = 201){
                setNotification(true);
                setNotificationObject({
                    notification: 'success',
                    title: 'Success!',
                    message: res.data.message
                })
            } else {
                setNotification(true);
                setNotificationObject({
                    notification: 'warning',
                    title: 'Error!',
                    message: res.data.message
                })
            }
        })
        .catch(error => {
            console.log(error);
            setNotification(true);
            setNotificationObject({
                notification: 'warning',
                title: 'Error!',
                message: error.response.data.message
            })
        });

        setFirstname('');
        setLastname('');
        setCompany('');
        setEmail('');
        setTwilioSid('');
        setTwilioAuth('');
        setMessagingServiceSid('');
        setContentSid('');
    };

    return (
        <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <Notification notification={notification} notificationObject={notificationObject} />
        <div
            className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
            aria-hidden="true"
        >
            <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
                clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            />
        </div>
        <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Register a new company</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
            Enter the details below of the company you want to onboard.
            </p>
        </div>
        <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                First name
                </label>
                <div className="mt-2.5">
                <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    required
                    value={firstname} // Set the value from the state
                    onChange={(e) => setFirstname(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>
            <div>
                <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                Last name
                </label>
                <div className="mt-2.5">
                <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    required
                    value={lastname} // Set the value from the state
                    onChange={(e) => setLastname(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                Company
                </label>
                <div className="mt-2.5">
                <input
                    type="text"
                    name="company"
                    id="company"
                    required
                    value={company} // Set the value from the state
                    onChange={(e) => setCompany(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Email
                </label>
                <div className="mt-2.5">
                <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    value={email} // Set the value from the state
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>

            <div className="relative sm:col-span-2">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm text-gray-500">Twilio details</span>
                </div>
            </div>
            
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Account SID
                <span><a target="_blank" href="https://www.twilio.com/content/dam/twilio-com/global/en/blog/legacy/2021/better-twilio-authentication-csharp-twilio-api-keys/Console_Home.webp"><QuestionMarkCircleIcon className="inline mx-1 mb-1 h-5 w-5 text-gray-400" aria-hidden="true" /></a></span>
                </label>
                <div className="mt-2.5">
                <input
                    type="text"
                    name="twilioSid"
                    id="twilioSid"
                    required
                    value={twilioSid} // Set the value from the state
                    onChange={(e) => setTwilioSid(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Auth Token
                <span><a target="_blank" href="https://www.twilio.com/content/dam/twilio-com/global/en/blog/legacy/2021/better-twilio-authentication-csharp-twilio-api-keys/Console_Home.webp"><QuestionMarkCircleIcon className="inline mx-1 mb-1 h-5 w-5 text-gray-400" aria-hidden="true" /></a></span>
                </label>
                <div className="mt-2.5">
                <input
                    type="password"
                    name="auth"
                    id="auth"
                    required
                    value={twilioAuth} // Set the value from the state
                    onChange={(e) => setTwilioAuth(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Messaging Service SID
                <span><a target="_blank" href="https://i.imgur.com/rlo6YgC.png"><QuestionMarkCircleIcon className="inline mx-1 mb-1 h-5 w-5 text-gray-400" aria-hidden="true" /></a></span>
                </label>
                <div className="mt-2.5">
                <input
                    type="text"
                    name="messagingServiceSid"
                    id="messagingServiceSid"
                    required
                    value={messagingServiceSid} // Set the value from the state
                    onChange={(e) => setMessagingServiceSid(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Content SID
                <span><a target="_blank" href="https://i.imgur.com/3crGijV.png"><QuestionMarkCircleIcon className="inline mx-1 mb-1 h-5 w-5 text-gray-400" aria-hidden="true" /></a></span>
                </label>
                <div className="mt-2.5">
                <input
                    type="text"
                    name="contentSid"
                    id="contentSid"
                    required
                    value={contentSid} // Set the value from the state
                    onChange={(e) => setContentSid(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>        
            </div>
            <div className="mt-10">
            <button
                type="submit"
                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Register company
            </button>
            </div>
        </form>
        </div>
    )
}
