import React, { Component, useEffect, useCallback } from 'react';
import { Link,useSearchParams } from 'react-router-dom';
import Nav from './Navigation';
import Conversation from './Conversation';
import Pagination from './Pagination';
import axios from '../api/axios';
import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon,FunnelIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import useAuth from '../hooks/useAuth';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Conversations = () => {

  const {auth} = useAuth();
  const [user, setUser] = useState({});

  useEffect(() => {
      if(auth){
          setUser({
              id: auth?.id,
              firstname: auth?.firstname,
              lastname: auth?.lastname,
              username: auth?.username,
              role: auth?.role,
              companyId: auth?.companyId
          })
      }
  }, [auth])



  const [sortOptions, setSortOptions] = useState([
    { index: 1, name: 'Abc ↑', sort: 'asc', field: 'name', href: '#', active: false },
    { index: 2, name: 'Abc ↓', sort: 'desc', field: 'name', href: '#', active: false },
    { index: 3, name: 'Newest', sort: 'desc', field: 'lastreceived', href: '#', active: true },
    { index: 4, name: 'Oldest', sort: 'asc', field: 'lastreceived', href: '#', active: false }
  ]);

  const [searchParams, setSearchParams] = useSearchParams();
  
  const [open, setOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [count, setCount] = useState('');
  const [page, setPage] = useState(1);

  const [sort, setSort] = useState('desc');
  const [field, setField] = useState('lastreceived');

  /*

  useEffect(() => {
    if (searchParams.size > 0) {
      let params = {};
  
      searchParams.forEach((value, key) => {
        const parsedValue = value === 'null' ? null : value;
  
        if (params[key]) {
          params[key] = Array.isArray(params[key]) ? [...params[key], parsedValue] : [params[key], parsedValue];
        } else {
          params[key] = parsedValue;
        }
      });
  
      const updatedFilters = filters.map((filter) => ({
        ...filter,
        options: filter.options.map((option) => {
          let isChecked = false;
  
          if (Array.isArray(params[filter.id])) {
            if (filter.id === 'sender' && params[filter.id].includes(option.value.split(':')[1])) {
              isChecked = true;
            } else if (params[filter.id].includes(option.value)) {
              isChecked = true;
            }
          } else if (params[filter.id] === option.value) {
            isChecked = true;
          }
  
          return { ...option, checked: isChecked };
        }),
      }));
  
      setFilters(updatedFilters);
      setQuery(params);
    } else {
      setQuery({'status':['new']});
    }
  }, [searchParams]); 

  */

  const handleSortChange = (index, clickedSort, clickedField) => {
    const updatedSortOptions = sortOptions.map((option) => {
      if (option.index === index) {
        return { ...option, active: true };
      } else {
        return { ...option, active: false };
      }
    });
    setSortOptions(updatedSortOptions);
    setSort(clickedSort);
    setField(clickedField);
  };
  
  function changePageIndex(index) {
    console.log('changing page to: '+index);
    setPage(index);
  }

  useEffect(() => {

      axios.get(`/conversations/${auth?.companyId}/${page}?sort=${sort}&field=${field}&status=null&status=new&status=expired`)
      .then(response => {
        setConversations(response.data.data);
        setCount(response.data.count);
        setPage(response.data.page);
        console.log(`${sort} ${field}`);
      })
      .catch(error => {
        setConversations([]);
        console.error('Error fetching messages:', error);
      });

  }, [page,sort,field]);

  return (
    <Nav >
    <div className="">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            
          </div>
        </Dialog>
      </Transition.Root>

      <div className="mx-auto max-w-3xl px-0 text-center sm:px-0 lg:max-w-full lg:px-0">
        <div className="py-12">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">All Conversations</h1>
          <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
            Conversations initiated with any of your WhatsApp profiles will appear here.
          </p>
        </div>

        <section aria-labelledby="filter-heading" className="border-t border-gray-200 py-6">

          <div className="flex items-center justify-between">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <FunnelIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 z-10 mt-2 w-20 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                      {({ active }) => {
                        return (
                          <button
                            onClick={() => handleSortChange(option.index, option.sort, option.field)}
                            className={classNames(
                              option.active ? 'bg-blue-100' : '',
                              'cursor-pointer w-20 block px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100'
                            )}
                          >
                            {option.name}
                          </button>
                        );
                      }}
                    </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </section>
      </div>
    </div>
        {
        
        conversations.length > 0 ? 
        <>
        <ul role="list" className="divide-y divide-gray-100">
            {conversations.map(conversation => (
                <Conversation
                key={conversation._id}
                conversation={conversation}
                />
            ))}
        </ul>

        <Pagination

        count={count}
        page={page}
        changePageIndex={changePageIndex}
        
        />
        </>
        :
        <>
        

        <div className="text-center">
          <CheckCircleIcon
          className="mx-auto h-12 w-12 text-gray-400"
          />
          
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No messages</h3>
          <p className="mt-1 text-sm text-gray-500">You're all caught up!</p>
        </div>


        </>
        }
        
    </Nav>
  )
}

export default Conversations;