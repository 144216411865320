import React, { Component, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Navigation';
import Conversation from './Conversation';
import Pagination from './Pagination';
import axios from '../api/axios';
import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { XMarkIcon,CheckCircleIcon,FunnelIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import useAuth from '../hooks/useAuth';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Inbox = () => {


  const {auth} = useAuth();
  const [user, setUser] = useState({});

  useEffect(() => {
      if(auth){
          setUser({
              id: auth?.id,
              firstname: auth?.firstname,
              lastname: auth?.lastname,
              username: auth?.username,
              role: auth?.role,
              companyId: auth?.companyId
          })
      }
  }, [auth])


  const [sortOptions, setSortOptions] = useState([
    { index: 1, name: 'Abc ↑', sort: 'asc', field: 'name', href: '#', active: false },
    { index: 2, name: 'Abc ↓', sort: 'desc', field: 'name', href: '#', active: false },
    { index: 3, name: 'Newest', sort: 'desc', field: 'lastreceived', href: '#', active: true },
    { index: 4, name: 'Oldest', sort: 'asc', field: 'lastreceived', href: '#', active: false }
  ]);

  const [filters, setFilters] = useState([
    {
      id: 'status',
      name: 'Status',
      options: [
        { index: 0, value: 'expired', label: 'Expired', checked: true },
        { index: 1, value: null, label: 'Unresolved', checked: true },
        { index: 2, value: 'resolved', label: 'Resolved', checked: false },
      ],
    },
    {
      id: 'sender',
      name: 'Sender',
      options: [
        { index: 0, value: 'whatsapp:+441616968246', label: 'whatsapp:+441616968246', checked: false },
        { index: 1, value: 'whatsapp:+441616968357', label: 'whatsapp:+441616968357', checked: false }
      ],
    }
  ]);
  
  const [open, setOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [count, setCount] = useState('');
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({'status':[null,'expired']});

  const [sort, setSort] = useState('desc');
  const [field, setField] = useState('lastreceived');

  const handleSortChange = (index, clickedSort, clickedField) => {
    const updatedSortOptions = sortOptions.map((option) => {
      if (option.index === index) {
        return { ...option, active: true };
      } else {
        return { ...option, active: false };
      }
    });
    setSortOptions(updatedSortOptions);
    setSort(clickedSort);
    setField(clickedField);
  };
  
  function changePageIndex(index) {
    console.log('changing page to: '+index);
    setPage(index);
  }

  useEffect(() => {
    const selectedStatusFilters = filters.find(filter => filter.id === 'status').options.some(option => option.checked);
  
    const queryString = selectedStatusFilters ? 
      Object.keys(query)
        .map(key => {
          if (Array.isArray(query[key])) {
            return query[key].map(value => `${key}[]=${encodeURIComponent(value)}`).join('&');
          } else {
            return `${key}=${encodeURIComponent(query[key])}`;
          }
        }).join('&') :
      '';
  
    axios.get(`/conversations/${auth?.companyId}/${page}?sort=${sort}&field=${field}&agent[]=${auth.username}&${queryString}`)
      .then(response => {
        setConversations(response.data.data);
        setCount(response.data.count);
        setPage(response.data.page);
        console.log(`${sort} ${field}`);
      })
      .catch(error => {
        setConversations([]);
        console.error('Error fetching messages:', error);
      });
  
  }, [page, sort, field, query]);
  

  return (
    <Nav >
    <div className="">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {filters.map((section) => (
                    <Disclosure as="div" key={section.name} className="border-t border-gray-200 px-4 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                              <span className="font-medium text-gray-900">{section.name}</span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} className="flex items-center">
                                  <input
                                    id={`filter-mobile-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-500"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="mx-auto max-w-3xl px-0 text-center sm:px-0 lg:max-w-full lg:px-0">
        <div className="py-12">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">Inbox</h1>
          <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
            Any conversation that you engage with will appear here.
          </p>
        </div>

        <section aria-labelledby="filter-heading" className="border-t border-gray-200 py-6">
          <div className="flex items-center justify-between">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <FunnelIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 z-10 mt-2 w-20 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                      {({ active }) => {
                        return (
                          <button
                            onClick={() => handleSortChange(option.index, option.sort, option.field)}
                            className={classNames(
                              option.active ? 'bg-blue-100' : '',
                              'cursor-pointer w-20 block px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100'
                            )}
                          >
                            {option.name}
                          </button>
                        );
                      }}
                    </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </section>
      </div>
    </div>

        {  
        conversations.length > 0 ? 
        <>
        <ul role="list" className="divide-y divide-gray-100">
            {conversations.map(conversation => (
                <Conversation
                key={conversation._id}
                conversation={conversation}
                />
            ))}
        </ul>

        <Pagination

        count={count}
        page={page}
        changePageIndex={changePageIndex}
        
        />
        </>
        :
        <>
        

        <div className="text-center">
          <CheckCircleIcon
            className="mx-auto h-12 w-12 text-gray-400"
            />
          
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No messages</h3>
          <p className="mt-1 text-sm text-gray-500">You're all caught up!</p>
        </div>


        </>
        }
    </Nav>
  )
}

export default Inbox;