import React, { Component, useState, useEffect } from 'react';
import { Link,useSearchParams,useNavigate } from 'react-router-dom';
import Error from './Error';
import axios from '../api/axios';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import logo from '../images/logo.png';

export default function Register() {

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [tokenValid,setTokenValid] = useState(false);

    const [errorTrue, setErrorTrue] = useState(false);
    const [error, setError] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [username, setUsername] = useState('');
    const [company, setCompany] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
      const token = searchParams.get('token');
      axios.get(`/users/register/${token}`)
      .then(response => {
        console.log(response.data);
        if(response.data.registered){
          navigate('/login');
        }
        setTokenValid(true);
        setFirstname(response.data.firstname);
        setUsername(response.data.username);
        setCompany(response.data.company);
      })
      .catch(error => {
        setTokenValid(false);
        console.error('Error:', error);
      });
    }, [])

    const handleSubmit = e => {
      e.preventDefault();
  
      const userObject = {
        firstname: firstname,
        lastname: lastname,
        username: username,
        password: password
      };
  
      axios.patch(`/users/register`, userObject)
        .then(res => {
          console.log(res.data);
          navigate('/login');
        })
        .catch(error => {
          console.log(error);
        });
  
      setFirstname('');
      setLastname('');
      setUsername('');
      setCompany('');
      setRole('');
      setPassword('');
    };


    //console.log(searchParams.get('token'))

    return (

      tokenValid ?

      <>
        <Error state={errorTrue} error={error} onClose={() => setErrorTrue(false)}/>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-30 w-auto"
              src={logo}
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Register your account
            </h2>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Firstname
                </label>
                <div className="mt-2">
                  <input
                    id="first-name"
                    name="first-name"
                    type="text"
                    autoComplete="new-password"
                    value={firstname} // Set the value from the state
                    onChange={(e) => setFirstname(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>


              <div>
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Lastname
                </label>
                <div className="mt-2">
                  <input
                    id="last-name"
                    name="last-name"
                    type="text"
                    autoComplete="new-password"
                    required
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="username"
                    name="username"
                    type="text"
                    required
                    autoComplete="new-password"
                    disabled
                    defaultValue={username}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                    />
                </div>
              </div>

              <div>
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Company
                </label>
                <div className="mt-2">
                  <input
                    id="username"
                    name="username"
                    type="text"
                    required
                    autoComplete="new-password"
                    disabled
                    defaultValue={company}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                    />
                </div>
              </div>
  
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                </div>
                
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type={
                      passwordVisible ? 'text' : 'password'
                    }
                    name="password"
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={(e) => setPasswordVisible(!passwordVisible)}>

                    {
                      passwordVisible ? 

                      <EyeSlashIcon className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />

                      :

                      <EyeIcon className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />

                    }
                  </div>
                </div>
              </div>
  
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </>

      :

      <>
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">400</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Link has expired</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">We recommend you contact the account owner to send you a new invitation.</p>
        </div>
    </main>
    </>


    )
  }  