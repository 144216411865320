import { useState, useEffect } from 'react';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';

export default function Pagination({ page, count, changePageIndex }) {
  const [limit, setLimit] = useState(10); // Default limit value can be adjusted
  const [pages, setPages] = useState(1);
  const siblingCount = 3;

  useEffect(() => {
    setPages(Math.ceil(count / limit));
  }, [count, limit]);

  const generateSiblings = () => {
    let array = [];
    for (let i = 0; i < siblingCount; i++){

      let a = page - i;
      let b = page + i;
      if(a >= 1 && a != page){
        array.unshift(a)
      }
      if(b <= page+siblingCount && b <= pages){
        array.push(b);
      }
    }
    return array
  };

  function Previous() {
    return (
      <div className="-mt-px flex w-0 flex-1">
        <a
          href="#"
          onClick={() => changePageIndex(page - 1)}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-500 hover:text-gray-500"
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-black/25" aria-hidden="true" />
          Previous
        </a>
      </div>
    );
  }

  function Next() {
    return (
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          href="#"
          onClick={() => changePageIndex(page + 1)}
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-black"
        >
          Next
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-black/25" aria-hidden="true" />
        </a>
      </div>
    );
  }

  function Span() {
    return (
      <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
        ...
      </span>
    );
  }

  function Number({ active, number }) {
    return (
      <a
        href="#"
        onClick={() => changePageIndex(number)}
        className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
          active
            ? 'border-black text-black'
            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-black'
        }`}
        aria-current={active ? 'page' : undefined}
      >
        {number}
      </a>
    );
  }

  function Numbers() {
    const siblings = generateSiblings();

    return (
      <>
        {page > 1 ? <Previous /> : <div className="-mt-px flex w-0 flex-1"></div>}
        <div className="hidden md:-mt-px md:flex">
          {siblings[0] > 1 && (
            <>
              <Number key={1} active={false} number={1} />
              <Span />
            </>
          )}
          {siblings.map((sibling) => (
            <Number key={sibling} active={sibling === page} number={sibling} />
          ))}
          {siblings[siblings.length - 1] < pages && (
            <>
              <Span />
              <Number key={pages} active={false} number={pages} />
            </>
          )}
        </div>
        {page < pages ? <Next /> : <div className="-mt-px flex w-0 flex-1 justify-end"></div>}
      </>
    );
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <Numbers />
    </nav>
  );
}
