import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import Nav from './Navigation';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';

export default function Support() {

    const {auth} = useAuth();

    const [user,setUser] = useState({});

    useEffect(() => {
        if(auth){
        setUser(auth);
        }
    }, [auth])
    
    const [priority, setPriority] = useState('Low');
    const [description, setDescription] = useState('');

    const handleSubmit = e => {
        e.preventDefault();

        const ticketObject = {
            firstname: user?.firstname,
            lastname: user?.lastname,
            email: user?.username,
            company: user?.company,
            priority: priority,
            description: description
        };

        axios.post(`/support`, ticketObject)
        .then(res => {
            console.log(res.data);
        })
        .catch(error => {
            console.log(error);
        });

        setPriority('Low');
        setDescription('');
        
    };


  return (
    <Nav>
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Submit a support ticket</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
          We will do our best to resolve any problems that you have but please remember that this service is in beta therefore you may experience some bugs.  If you do experience a bug, it's likely we may already be aware of it, however, if you feel it is greatly affecting your ability to use the service, then please don't hesitate to reach out. We also appreciate any feedback, no matter how small or significant, that may improve the user experience.
          </p>
          <br></br>
            <p className="mt-1 text-sm leading-6 text-gray-600">
            If you would like to submit a support ticket, we would ask that you triage your problem appropriately. 
            </p>
            <br></br>
            <ul class="list-none text-sm leading-6 text-gray-600">
                <li><span className="font-semibold">Low - </span> Possible bug that does not affect the day to day use of the service - General questions or feature requests</li>
                <li><span className="font-semibold">Medium - </span> Intermittent issues and reduced quality of service. A workaround may be available</li>
                <li><span className="font-semibold">High - </span> Complete loss of service or a significant feature that is completely unavailable</li>
            </ul>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div>
                <label htmlFor="priority" className="block text-sm font-medium leading-6 text-gray-900">
                Priority
                </label>
            <select
                id="priority"
                name="priority"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={priority}
                defaultValue="Low"
                onChange={(e) => setPriority(e.target.value)}
            >
                <option>Low</option>
                <option>Medium</option>
                <option>High</option>
            </select>
            </div>

            <div className="col-span-full">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <p className="mt-1 text-sm leading-6 text-gray-600">Explain in as much detail the problem you are having.</p>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  value={description} // Set the value from the state
                  onChange={(e) => setDescription(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
      </div>
    </form>
    </Nav>
  )
}
