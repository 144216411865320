import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { DocumentIcon } from '@heroicons/react/24/solid'

export default function MediaPanel(props) {
    const { onClose } = props;
    const [open, setOpen] = useState(props.state);
    const [allMedia, setMedia] = useState([]);


    useEffect(() => {
        setOpen(props.state);
        setMedia(props.media)
    }, [props.state,props.media,open]);

    return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          All Media
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">

                    {/*<div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          Practise caution when opening unkown files 
                        </p>
                      </div>
                    </div>
                  </div>*/}

                    <div class="grid grid-cols-3 gap-4">
                        {
                        
                        allMedia.map((media, index) => (
                            media.mediatype == 'application/pdf' ? 
                            <a target='_blank' href={media.mediaurl}>
                              <div class="relative bg-slate-200 overflow-hidden rounded-md h-32 w-auto">
                                  <DocumentIcon className="text-slate-400 relative m-auto mt-5 h-12 w-12" aria-hidden="true" />
                                  <p class="text-sm text-center">Document</p>
                                  <p class="text-xs text-center truncate overflow-hidden mx-2">.pdf</p>
                              </div>
                            </a>
                            :
                            <div class="relative overflow-hidden rounded-md h-32 w-fit">
                                <a target='_blank' href={media.mediaurl}><img class="object-contain" src={media.mediaurl}></img></a>
                            </div>
                        ))
                        
                        }
                    </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}