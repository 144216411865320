import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import axios from '../api/axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function EditUser(props) {

  const navigate = useNavigate();

  const { onClose,user } = props;
    
  const [open, setOpen] = useState(props.state);

  const [deleteUser, setDeleteUser] = useState(false);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        username: user.username,
        role: user.role
    })
  }, [user,open])

  const closePanel = e => {
    onClose();
    setFormData({
        id: "",
        firstname: "",
        lastname: "",
        username: "",
        role: "User"
    })
    setDeleteUser(false);
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    const userObject = {
      data: {
        id: formData.id,
        firstname: formData.firstname,
        lastname: formData.lastname,
        username: formData.username,
        password: formData.password
      }
    };
    if(deleteUser){
      try {
          const response = await axios.delete(`/users`, userObject);
          closePanel();
          navigate('/team');
      } catch (error) {
        console.log(error);
        //setErrorTrue(true);
        //setError(error.response.data.message);
      }
    } else {
      try {
        const response = await axios.patch(`${backendUrl}/users`, userObject.data);
            closePanel();
            navigate('/team');
        } catch (error) {
          console.log(error);
          //setErrorTrue(true);
          //setError(error.response.data.message);
        }
    }

  };

  useEffect(() => {
    setOpen(props.state);
  }, [props.state]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closePanel}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Edit user
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={closePanel}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-0 flex-1 px-4 sm:px-6">



                      <form onSubmit={handleSubmit}>
                        <div className="space-y-12">
                          <div className="border-b border-gray-900/10 pb-12">
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                  First name
                                </label>
                                <div className="mt-2">
                                  <input
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    autoComplete="email"
                                    value={formData.firstname}
                                    onChange={(e) => setFormData({...formData, firstname: e.target.value})}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                              <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                  Last name
                                </label>
                                <div className="mt-2">
                                  <input
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    autoComplete="email"
                                    value={formData.lastname}
                                    onChange={(e) => setFormData({...formData, lastname: e.target.value})}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>   
                              <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                  Email address
                                </label>
                                <div className="mt-2">
                                  <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    disabled
                                    value={formData.username}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50"
                                  />
                                </div>
                              </div>  
                              <div className="sm:col-span-4">
                                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                  Role
                                </label>
                                <select
                                  id="role"
                                  name="role"
                                  value={formData.role}
                                  onChange={(e) => setFormData({...formData, role: e.target.value})}
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue="User"
                                >
                                  <option>Admin</option>
                                  <option>User</option>
                                </select>
                              </div>

                              <div className="sm:col-span-4">
                                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                  Delete user
                                </label>
                                <input
                                  id="delete"
                                  aria-describedby="comments-description"
                                  name="delete"
                                  type="checkbox"
                                  checked={deleteUser}
                                  onChange={(e) => setDeleteUser(e.target.checked)}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                          <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={closePanel}>
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}